"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REQUEST_MORE_HOURS_TEMPLATE = void 0;
exports.REQUEST_MORE_HOURS_TEMPLATE = `Hi {EXECUTIVE_NAME},

This is a reminder that we've used 80% or more of the plan hours you've prepaid for the month.

Your current billing period ends on {LAST_DAY_OF_CURRENT_BILLING_CYCLE}, and we've currently used {CURRENT_HOURS_USED} out of the {TOTAL_HOURS} hour(s) included in your plan.

You can purchase additional hours in 5-hour increments, applicable to the rest of the current billing period. Unused hours won't roll over into the next month.

Let me know if you'd like me to add additional hours to your plan at any point. If not, or if I don't hear from you, I'll continue to work until we've hit 100% of your plan, and pause work on your account at the best stopping point I can find. You'll get another email when we've hit the plan limit, and I will resume work when the new billing cycle begins. 

Thanks,
{ASSISTANT_NAME}`;
